import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const AttirePage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=attire_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Antrekk</h1>
                    <p>
                        Gjester bes å kle seg sommerlig pent. Merk at brudgommen vil bære smoking så vi ber mannlige
                        gjester unnlate å ikle seg dette.
                    </p>
                    <p>
                        Vanligvis er sommernettene i Hellas gode og varme, så det anbefales å kle seg deretter.
                        Når det er sagt så ligger arrangementsstedet ved kysten og vind kan forekomme enkelte kvelder, så
                        det kan være lurt å ha med en ekstra jakke e.l. i tilfelle.
                    </p>

                </>
            }
            {lang == "en" &&
                <>
                    <h1>Attire</h1>
                    <p>
                        Guests are asked to dress informal and suitable for summer. The groom will be wearing a smoking / black tie, so other male guests are asked not to be wearing this.
                    </p>
                    <p>
                        Usually summer nights in Greece are warm so take this into account when choosing your attire. Due to the case of wind it is advised to bring a jacket or similar.
                    </p>
                </>
            }

        </main>
    )
}

export default AttirePage
